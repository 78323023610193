<template>
  <v-card class="elevation-0 mx-8 my-2 py-4 px-8">
    <v-row class="ma-0 pb-0">
        <v-card-title>
            Huecos
        </v-card-title>
        <v-spacer/>
        <div>
        <v-menu offset-y :close-on-content-click="closeDatePicker(month_filter)">
            <template v-slot:activator="{ on, attrs }">
                <v-text-field rounded outlined dense clearable v-model="month_filter" label="Meses" prepend-inner-icon="mdi-calendar" v-bind="attrs" readonly v-on="on" class="mt-0"></v-text-field>
            </template>
            <v-date-picker v-model="month_filter" type="month" range></v-date-picker>
        </v-menu>
        </div>
    </v-row>
    
    <v-data-table
        height="600"
        fixed-header
        :loading="loading"
        :headers="headers_to_show"
        :items="records">
    </v-data-table>

  </v-card>
</template>

<script>
import VueHorizontal from 'vue-horizontal';
import axios from "axios"
export default {
    components: {
        VueHorizontal
    },
    data(){
        return{
            loading:true,
            month_filter:[],
            records:[],
            headers:[],
            headers_to_show:[],
        }
    },
    watch:{
        month_filter:{
            handler(){
                if(this.month_filter==null){
                    this.loading = true
                    this.headers_to_show = this.headers
                    this.loading = false
                }else if(this.month_filter.length==2){
                    this.loading = true
                    console.log(this.headers)
                    const from = this.month_filter[0].slice(0,4) + '' +  this.month_filter[0].slice(5,7)
                    const to =  this.month_filter[1].slice(0,4) + '' +  this.month_filter[0].slice(5,7)
                    console.log(from)
                    console.log(to)
                    const months = this.headers.filter(header=>
                        (header.key*1) >= (from*1)
                        &&
                        (header.key*1) <= (to*1)
                    )
                    this.headers_to_show = [{text:'Cliente', value:'name', width: '100%'}].concat(months)
                    this.loading = false
                }
            }, deep:true
        }
    },
    created(){
        axios.get(process.env.VUE_APP_BACKEND_ROUTE + "api/v2/huecos").then(response=>{
            const resp = response.data.map(id=>{return{
                ...id,
                month_year:this.monthName(id.month) + ' ' + id.year,
            }});
            this.headers = [
                { text: 'Cliente', value: 'name', width: '300px' },
                ...[...new Set(resp.map(objeto => objeto.month_year))].map(month_year => {
                    const [month, year] = month_year.split(' ');
                    const key = year  + '' +  this.monthNumber(month)
                    return {
                        text: month_year,
                        value: month_year,
                        key: key,
                    };
                }),
            ];
            
            const grouped = {};
            for (const item of resp) {
                if (!grouped[item.name]) {
                    grouped[item.name] = {
                        name: item.name,
                    };
                }
                grouped[item.name][item.month_year] = (item.sale_amount*1).toLocaleString('es-MX', { style: 'currency', currency: 'MXN',}) + '\n' + this.twoDecimals(item.kg_amount) + ' kgs'
            }
            this.records = Object.values(grouped);

            this.month_filter = [(new Date().getFullYear() + '-01'), (new Date().getFullYear() + '-12')]

            this.loading = false
        })
    },
    methods:{
        compareDates(key, date, what){
            if(what=='from'){
                if(new Date(key)>=new Date(date)){
                    return true
                }
            }else if(what=='to'){
                if(new Date(key)<=new Date(date)){
                    return true
                }
            }
        },
        twoDecimals(number){
            if(number!=null&number!=undefined){
                return number.toFixed(2)
            }
        },
        closeDatePicker(dates){
            if(dates!=undefined && dates.length==2){
                return true
            }else{
                return false
            }
        },
        monthName(month){
            switch(month){
                case 1: return 'Enero'
                case 2: return 'Febrero'
                case 3: return 'Marzo'
                case 4: return 'Abril'
                case 5: return 'Mayo'
                case 6: return 'Junio'
                case 7: return 'Julio'
                case 8: return 'Agosto'
                case 9: return 'Septiembre'
                case 10: return 'Octubre'
                case 11: return 'Noviembre'
                case 12: return 'Diciembre'
            }
        },
        monthNumber(month){
            switch(month){
                case 'Enero': return '01'
                case 'Febrero': return '02'
                case 'Marzo': return '03'
                case 'Abril': return '04'
                case 'Mayo': return '05'
                case 'Junio': return '06'
                case 'Julio': return '07'
                case 'Agosto': return '08'
                case 'Septiembre': return '09'
                case 'Octubre': return '10'
                case 'Noviembre': return '11'
                case 'Diciembre': return '12'
            }
        },
    }
}
</script>